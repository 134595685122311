import axios from "axios";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";

const ServiceBox = ({ service }) => {
  return (
    <div class="card-service">
      <div class="card-iconbox">
        <img
          src={service.icon}
          alt="Graphic Design"
          width="24"
          style={{ alignSelf: "center" }}
        />
      </div>
      <div class="card-title">{service.title}</div>
      <div class="card-body-text">{service.description}</div>
    </div>
  );
};

export default function Services() {
  const { isLoading, data: services } = useQuery("services", () => {
    return axios.get("services/all/").then(function (response) {
      return response.data;
    });
  });

  if (isLoading) {
    return <div style={{ height: "100vh" }}></div>;
  }

  return (
    <FadeIn>
      <div id="services">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-md-12 about-gradient about-text text-sm-center">
            Services
          </div>
          <div class="col-lg-6 col-sm-12 col-md-12 serviceinfo text-center d-none d-lg-block"></div>
        </div>
        <div
          class="row"
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: 30,
            marginBottom: 100,
          }}
        >
          {services.map((service) => (
            <ServiceBox service={service} />
          ))}
        </div>
      </div>
    </FadeIn>
  );
}
