import logo from "../assets/logo.png";
export function NavBar() {
  return (
    <>
      <div class="d-none  d-sm-block" id="my-navbar">
        <img src={logo} alt="Suzu logo" height="24" />
        <div id="nav-right">
          <a href="#aboutme">About me</a>
          <a href="#services">Services</a>
          <a href="#works">Work</a>
          <a href="#contactme">
            <span class="grident-text">Contact me</span>
            👋🏻
          </a>
        </div>
      </div>
      <div class="d-block d-sm-none topnav" id="collapse-navbar">
        <img src={logo} alt="Suzu logo" height="24" />

        <div class="float-right hamcontainer" onclick="navbar()">
          <div class="" id="ham-btn">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
          </div>
        </div>
        <a href="#aboutme">About me</a>
        <a href="#services">Services</a>
        <a href="#works">Work</a>
        <a href="#contactme">
          <span class="grident-text">Contact me</span>
          👋🏻
        </a>
      </div>
    </>
  );
}
