import aboutIcon from "../assets/abouticon.png";
import Helmet from "react-helmet";
import { ReactSEOMetaTags } from "react-seo-meta-tags";

import axios from "axios";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function AboutMe() {
  const { isLoading, data: aboutme } = useQuery("aboutme", () => {
    return axios.get("aboutme/detail/").then(function (response) {
      return response.data;
    });
  });

  if (isLoading) {
    return <div style={{ height: "100vh" }}></div>;
  }
  // return <></>;
  return (
    <FadeIn>
      <div class="row" id="aboutme">
        <div class="col-md-6 col-lg-5 col-sm-12">
          <div class="photo-frame">
            <link rel="preload" as="image" href={aboutme.image} />
            <link rel="preload" as="image" href={aboutme.thumbnail} />
            <LazyLoadImage
              alt="Suzu logo"
              src={aboutme.image}
              placeholderSrc={aboutme.thumbnail}
              effect="blur"
              threshold={50}
              width="100%"
              loading="lazy"
              className="suzu-image"
              wrapperClassName="suzu-image"
            />
          </div>
        </div>

        <div class="col-md-6 col-lg-7 col-sm-12">
          <div class="d-md-none d-block " style={{ height: 30 }}></div>
          <div class="img-box d-flex justify-content-center justify-content-sm-center justify-content-md-start">
            <img src={aboutIcon} alt="about icon" width="60" />
          </div>
          <div class="d-flex justify-content-center justify-content-sm-center justify-content-md-start">
            <div class="about-gradient about-text">About me</div>
          </div>
          <div class="text text-align-sm-center">{aboutme.about}</div>
          <div class="d-flex justify-content-center justify-content-sm-center  justify-content-md-start">
            <a
              href={aboutme.resume}
              class="resume-btn"
              target="_blank"
              rel="noreferrer"
            >
              Resume
            </a>
          </div>
        </div>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            url: "https://sujatakhadka.com.np",
            title: "Suzu",
            datePublished: "2022",
            description: aboutme.about,
            language: "en-US",
            image: aboutme.image,
            developer: {
              name: "Paras Rai",
              email: "erparasrai@gmail.com",
              url: "https://erparasrai.com.np",
            },
            author: {
              email: "sujatakhadka69@.com",
              name: "Sujata Khadka",
              image: aboutme.image,
            },
            site: {
              siteName: "Portfolio",
              searchUrl: "https://sujatakhadka.com.np",
            },
          }}
        />
      </div>
    </FadeIn>
  );
}
