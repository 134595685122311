import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GetInTouch() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const validate = () => {
    let isValid = true;
    let errors = {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    };
    if (fullName === "") {
      isValid = false;
      errors.fullName = "Enter full name";
    }
    if (email === "") {
      isValid = false;
      errors.email = "Enter email";
    } else if (!email.includes("@")) {
      isValid = false;
      errors.email = "Enter valid email";
    }
    if (subject === "") {
      isValid = false;
      errors.subject = "Enter subject";
    }
    if (message === "") {
      isValid = false;
      errors.message = "Enter message";
    }
    setErrors(errors);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validate()) {
      axios
        .post("message/send/", {
          full_name: fullName,
          email: email,
          subject: subject,
          message: message,
        })
        .then((response) => {
          if (response.status === 200) {
            document.getElementById("contact_form").reset();
            toast.success(response.data, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error(response.data, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    }
  };

  const { isLoading, data: contacts } = useQuery("contacts", () => {
    return axios.get("aboutme/contacts/").then(function (response) {
      return response.data;
    });
  });

  return (
    <div id="contactme">
      <div class="getintouch-text">Get in Touch</div>

      <div class="getintouch-body">
        My service personnel are always ready to answer any of your enquiries.
        Reach us
        <br />
        using the contact form below.
      </div>
      <div class="contact-box">
        <form
          onSubmit={sendEmail}
          id="contact_form"
          style={{ padding: 34, textAlign: "left", margin: "auto" }}
        >
          <div class="row">
            <div class="col-md-6" style={{ marginTop: 24 }}>
              <label for="full_name">Full name</label>
              <br />
              <input
                type="text"
                id="name"
                minLength={3}
                name="full_name"
                onChange={(e) => setFullName(e.target.value)}
              />
              <br />
              <div className="errorMsg">{errors.fullName}</div>
            </div>

            <div class="col-md-6" style={{ marginTop: 24 }}>
              <label for="email">Email address</label>
              <br />
              <input
                type="email"
                id="email"
                pattern="[^ @]*@[^ @]*"
                minLength={3}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="errorMsg">{errors.email}</div>
            </div>
          </div>
          <div style={{ height: 24 }}></div>
          <label for="subject">Subject</label>
          <br />
          <input
            type="text"
            minLength={4}
            id="subject"
            name="subject"
            onChange={(e) => setSubject(e.target.value)}
          />
          <div className="errorMsg">{errors.subject}</div>
          <div style={{ height: 24 }}></div>
          <label for="message">Message</label>
          <br />
          <textarea
            type="text"
            col="10"
            minLength={10}
            height="500"
            id="message"
            name="message"
            style={{ height: 120 }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <div className="errorMsg">{errors.message}</div>
          <div style={{ height: 36 }}></div>
          <button type="submit" class="hire-btn w-100">
            Send message
          </button>
        </form>
      </div>
      {isLoading ? (
        <div class="row footer">
          Address: -
          <br class="d-block d-md-none footer-padding" />
          <span class="d-none d-md-block footer-padding"></span>
          Contact: -
          <br class="d-block d-md-none" />
          <span class="d-none d-md-block footer-padding"></span>
          Email: -
        </div>
      ) : (
        <div class="row footer">
          Address: {contacts.address}
          <br class="d-block d-md-none footer-padding" />
          <span class="d-none d-md-block footer-padding"></span>
          Contact: {contacts.contact}
          <br class="d-block d-md-none" />
          <span class="d-none d-md-block footer-padding"></span>
          Email: {contacts.email}
        </div>
      )}

      <ToastContainer style={{ fontSize: 13 }} />
    </div>
  );
}
