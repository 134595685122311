// import './styles.css'
import { useEffect } from "react";
import AboutMe from "./components/about_me";
import GetInTouch from "./components/get_in_touch";
import { Greeting } from "./components/greeting";
import { NavBar } from "./components/navbar";
import Projects from "./components/projects";
import Services from "./components/services";
import SocialMedia from "./components/social_media";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";

import "./App.css";

const queryClient = new QueryClient();

function App() {
  axios.defaults.baseURL = "https://suzuapi.erparasrai.com.np/api/";
  // axios.defaults.baseURL = "http://localhost:8000/api/";

  console.log = () => {};

  useEffect(() => {
    var scrollpos = window.scrollY;
    var header = document.getElementById("my-navbar");
    var header2 = document.getElementById("collapse-navbar");

    function add_class_on_scroll() {
      header.classList.add("scrolled");
      header2.classList.add("scrolled");
    }

    function remove_class_on_scroll() {
      header.classList.remove("scrolled");
      header2.classList.remove("scrolled");
    }

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;
      console.log(scrollpos);
      if (scrollpos > 80) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  });
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <NavBar />

        <div class="container">
          <Greeting />

          <div class="container">
            <SocialMedia />
            <AboutMe />
          </div>
          <Services />
          <Projects />
          <GetInTouch />
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;
