import axios from "axios";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";

export function Greeting() {
  const { isLoading, data: greeting } = useQuery("greeting", () => {
    return axios.get("aboutme/greeting/").then(function (response) {
      return response.data;
    });
  });

  if (isLoading) {
    return <div style={{ height: "100vh" }}></div>;
  }
  return (
    <FadeIn>
      <section>
        <div id="intro-box">
          <div style={{ marginBottom: 14 }}>Hello 👋🏻</div>
          <div class="intro-text intro-gradient">
            {greeting.greet}
            <br />
            {greeting.resignation}
          </div>
          <div class="intro-line">{greeting.description}</div>
        </div>
      </section>
    </FadeIn>
  );
}
