import axios from "axios";
import Helmet from "react-helmet";
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProjectRow = ({ project, isRight }) => {
  return (
    <div
      class={
        isRight
          ? "row work-group"
          : "row flex-lg-row-reverse flex-row flex-md-row-reverse  d-flex align-items-center"
      }
    >
      <div class="float-lg-right float-md-right col-md-6 col-lg-6 col-sm-12">
        <div class="photo-frame">
          <link rel="preload" as="image" href={project.image} />
          <link rel="preload" as="image" href={project.placeholder} />
          <LazyLoadImage
            alt={project.name}
            src={project.image}
            placeholderSrc={project.placeholder}
            effect="blur"
            threshold={50}
            delayMethod="debounce"
            loading="lazy"
            className="project-image"
            wrapperClassName="project-image"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-12  text-lg-start text-sm-center text-lg-left text-md-left text-center work-box">
        <span class="about-gradient">{project.name}</span> ({project.info})
        <br />
        <br />
        <a
          href={project.link}
          target="_blank"
          class="hire-btn"
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          Visit site
        </a>
      </div>
      <ReactSEOMetaTags
        render={(el) => <Helmet>{el}</Helmet>}
        website={{
          site: {
            siteName: project.name,
            searchUrl: project.link,
          },
        }}
      />
    </div>
  );
};

export default function Projects() {
  const { isLoading, data: projects } = useQuery("projects", () => {
    return axios.get("projects/all/").then(function (response) {
      return response.data;
    });
  });

  if (isLoading) {
    return <div style={{ height: "100vh" }}></div>;
  }

  return (
    <FadeIn>
      <div id="works">
        <div
          class="about-gradient about-text text-center"
          style={{ alignSelf: "center" }}
        >
          Let’s Check My Recent Work
        </div>
        <div>
          {projects.map((project, index) => (
            <ProjectRow project={project} isRight={index % 2 === 1} />
          ))}
        </div>
      </div>
    </FadeIn>
  );
}
