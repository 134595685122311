import axios from "axios";
import { useQuery } from "react-query";
import FadeIn from "react-fade-in/lib/FadeIn";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SocialMediaBox = ({ social }) => {
  return (
    <a
      class="social-btn my-2"
      href={social.link}
      target="_blank"
      aria-label={social.description}
      rel="noreferrer"
    >
      <link rel="preload" as="image" href={social.image} />
      <LazyLoadImage
        alt={social.description}
        height={28}
        src={social.image}
        placeholderSrc={social.image}
        effect="blur"
        width={28}
        loading="lazy"
        style={{ alignSelf: "center" }}
      />
    </a>
  );
};

export default function SocialMedia() {
  const { isLoading, data: socialMedias } = useQuery("socialmedia", () => {
    return axios.get("socialmedia/all/").then(function (response) {
      return response.data;
    });
  });

  if (isLoading) {
    return <div style={{ height: "100vh" }}></div>;
  }

  return (
    <FadeIn>
      <div style={{ textAlign: "center", margin: 40 }}>
        <button onClick="" class="hire-btn">
          Hire me
        </button>
        <div
          style={{
            display: "block",
            textAlign: "center",
            marginTop: 60,
            marginBottom: 60,
          }}
        >
          {socialMedias.map((social) => (
            <SocialMediaBox social={social} />
          ))}
        </div>
      </div>
    </FadeIn>
  );
}
